@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap');

$color-red: #C81246;
$light-grey: #D8D8D8;
$dark-grey: #888;

$color-red-light: #D57594;
$color-red-off: #EAF1F9;


$intro-image: url('./../img/prorail-5d-intro-image-eng.jpg');

// MARKER POSITIES

$marker1-top: 72%;
$marker1-left: 44%;

$marker2-top: 49%;
$marker2-left: 42%;

$marker3-top: 26%;
$marker3-left: 50%;

$marker4-top: 22%;
$marker4-left: 68%;

$marker5-top: 40%;
$marker5-left: 59%;

$marker6-top: 65%;
$marker6-left: 60%;

//  Markers inwinnen
$marker7-top: 85%;
$marker7-left: 13%;

$marker8-top: 48%;
$marker8-left: 22%;

$marker9-top: 64%;
$marker9-left: 2%;

$marker10-top: 22%;
$marker10-left: 15%;

// Markers gebruik
$marker11-top: 13%;
$marker11-left: 93%;

$marker12-top: 59%;
$marker12-left: 97%;

$marker13-top: 84%;
$marker13-left: 87%;

$marker14-top: 25%;
$marker14-left: 96.5%;


// Markers B

$markerb1-top: 45%;
$markerb1-left: 67.1%;

$markerb1_1-top: 22%;
$markerb1_1-left: 18%;

$markerb1_2-top: 90%;
$markerb1_2-left: 3%;

$markerb1_3-top: 47%;
$markerb1_3-left: 95%;

$markerb1_4-top: 40%;
$markerb1_4-left: 55%;

$markerb1_5-top: 74%;
$markerb1_5-left: 62%;

$markerb1_6-top: 77%;
$markerb1_6-left: 30%;

$markerb1_7-top: 6%;
$markerb1_7-left: 64%;

// Tags
html {
  overflow: hidden;
}

body{

  /* prevent overscroll bounce*/
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  /* iOS velocity scrolling */

	margin: 0px;
	padding: 0px;
	font-family: 'Montserrat', sans-serif, Arial;
	font-size: 14px;
	line-height: 26px;
}

ul {
	margin-left: 20px;
}

h1, h2 {
	color: $color-red;
	font-weight: 600;
	font-size: 1.5em;
	line-height: 1.3em;
	text-transform: uppercase;
	margin-bottom: 30px;
	max-width: 90%;
}

h3 {
	color: black;
	font-size: 1.3em;
	line-height: 1.3em;
	font-weight: 300;
	text-transform: uppercase;
}

a {
	color: $color-red;
	text-decoration: none;
}

.txtdivider {
	margin: 40px;
}

// Helpers

.red {
	color: $color-red;
	cursor: pointer;
}

.uppercase {
	text-transform: uppercase;
}

.pointer {
	cursor: pointer;
}

.txtcontainer {
	opacity: 0;
}

// Classes
.extrainfo,
.grey {
	border: 1px solid $color-red;
	width: 100%;
	padding: 20px;
	margin: 20px 0px;
	font-weight: 600;
	font-style: italic;
}

.slidebtn {
	background-color: $color-red-light;
	height: 26px;
	width: 40px;
	display: inline-block;
	margin-right: 5px;
	border-radius: 12px;
	position: relative;
	top: 7px;	
}

.slidebtn .switch {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	margin-left: 3px;
	background-color: $color-red-off;
	opacity: 0.5;
	position: absolute;
	top: 3px;
}


// IDs



#loader
{
	height: 100vh;
	width: 100vw;
	background-color: white;
	position: absolute;
	z-index: 1140;
	text-align: center;
	vertical-align: middle;

	.content {
		position: absolute;
		height: 150px;
		width: 300px;
		top: 50%;
		left: 50%;
		margin-top: -75px;
		margin-left: -150px;

	}
}


#logo {
	height: 48px;
	position: fixed;
	z-index: 1040;
	padding: 10px;
	border-radius: 0px 0px 5px 0px;
	box-shadow: 0px 0px 5px #d1d1d1;


	background-color: white;
		.logo {
			height: 20px;
		}
}

#breadcrumbs {
	height: 48px;
	background-color: white;
	position: fixed;
	z-index: 1030;
	padding: 15px 20px 15px 20px;
	border-radius: 0px 0px 5px 0px;
	box-shadow: 0px 0px 5px #d1d1d1;
	font-size: 11px;
	font-weight: bold;
	color: $color-red;

	.fa {
		margin: 0px 10px 0px 10px;
	}

	display:none;
}


#langbutton {
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: 100px;
	height: 30px;
	text-align: center;

	.large {
		width: 30px;
		position: absolute;
		right: 0px;
	}

	.small {
		width: 20px;
		height: 20px;
		right: 0px;
		margin-top: 5px;
		margin-left: -30px;
		position: absolute;
		display: block;
		-webkit-animation:ease-in-out forwards;
	    -webkit-animation-name: runback;
	    -webkit-animation-duration: 0.4s;
	}

	@-webkit-keyframes runback {
	    0% { right: 40px;}
	    100%{ right: 0px;}
	}
}


#langbutton:hover {

	.small {
		-webkit-animation:ease-in-out forwards;
	    -webkit-animation-name: run;
	    -webkit-animation-duration: 0.4s;
	}

	@-webkit-keyframes run {
	    0% { right: 0px;}
	    100%{ right: 40px;}
	}
}



#menubutton {
	position: absolute;
	z-index: 1040;
	top: 0px;
	right: 20px;
	background-color: white;
	width: 40px;
	text-align: center;
	padding: 10px 0px;
	border-radius: 0px 0px 5px 5px;
	color: $color-red;
	display: none;
	box-shadow: 0px 0px 5px #d1d1d1;
}

#closemarkerb1  {
	position: absolute;
	z-index: 1040;
	top: 0px;
	right: 80px;
	background-color: $color-red;
	width: 40px;
	text-align: center;
	padding: 10px 0px;
	border-radius: 0px 0px 5px 5px;
	box-shadow: 0px 0px 5px #d1d1d1;
	color: white;
	display:none;
}

.closemarkerb1 {
	cursor: pointer;
}

#sidebar {
	width: 350px;
	background-color: $color-red;
	padding-top: 60px;
	height: 100vh;
	position: fixed;
	right: -350px;
	top: 0px;
	z-index: 1050;

	ul {
		margin: 0px;
		padding: 0px;

		li {
			list-style: none;
			padding: 0px 30px;
			color: white;
			margin-bottom: 20px;

			a {
				text-decoration: none;
				color: white;
			}
		}
	
		li:hover {
			color:$color-red-light;
		}

		li.divider {
			height: 1px;
			padding: 0px;
			margin: 40px 0px;
			width: 100%;
			border-bottom: 1px solid $color-red-light;
		}

		li.mapitem {
			display: none;
		}
		li.bottom {
			position: absolute;
			bottom: 0px;
		}

		li#zoomtostart {
			cursor: default;
			color: $color-red-light;
		}

		li#zoomtostart:hover {
			cursor: default;
			color: $color-red-light !important;
		}
	}

	.closemenu {
		color: white;
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 1.5em;
	}
}


#homepage::after {
	content: '';
	background-image: $intro-image;
	background-repeat: no-repeat;
	background-position: center right;
	background-size: cover;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: -1;   
}

#homepage {
	width: 100vw;
	height: 100vh;
	overflow-y:auto;
	background-color: white;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1030;
	opacity: 1;
	padding: 5% 10% 0px 10%;


		
		.mobilebuttons {
			width: 100vw;
			position: fixed;
			background-color: $color-red;
			height: 60px;
			bottom: 0px;
			
			div.col-6 {
				text-align: center;
				color: white;
				border-right: 1px solid #fff;
				height: 60px;
				a {
					position: relative;
					top: 15px;
				}
			}

			div.col-6:last-child {
				border-right: 0px solid #fff;
			}
		}

		.startbuttons {
			margin-top: 60px;	

						
			.startbtn {
				background-color: $color-red;
				color: white !important;
				padding: 8px 20px 8px 50px;
				border-radius: 10px;
				position: relative;
				cursor: pointer;
				margin-right: 60px;
				display: inline-block;
			}


			.startbtn::before {
				font-family: "Font Awesome 5 Free";
				content: "\f04b";
				font-size: 20px;
				height: 50px;
				width: 50px; 
				border: 2px solid #fff;
				border-radius:50%;
				text-align: center;
				padding-top: 11px;
				padding-left: 3px;
				color: white;
				background-color: $color-red;
				
				position: absolute;
				left: -10px;
				top: -4px;
			}

			.startbtn:hover,
			.readerbtn:hover,
			.startbtn:hover::before,
			.readerbtn:hover::before {
					background-color: $color-red-light;
			}


			.readerbtn {
				background-color: $color-red;
				color: white !important;
				padding: 8px 20px 8px 50px;
				border-radius: 10px;
				position: relative;
				margin-top: 40px;
				cursor: pointer;
				display: inline-block;
			}

			.readerbtn::before {
				font-family: "Font Awesome 5 Free";
				content: "\f15c";
				font-size: 20px;
				height: 50px;
				width: 50px; 
				border: 2px solid #fff;
				border-radius:50%;
				text-align: center;
				padding-top: 10px;
				color: white;
				background-color: $color-red;
				
				position: absolute;
				left: -10px;
				top: -4px;
			}
	
		}
}


#sharebox {
	position: fixed;
	width: 400px;
	background-color: white;
	left: 50%;
	margin-left: -200px;
	top: -450px;
	z-index: 1060;
	box-shadow: 0px 0px 20px #333;
	padding: 30px;
	height: 400px;
	
	input {
		-webkit-transition: all 0.30s ease-in-out;
		-moz-transition: all 0.30s ease-in-out;
		-ms-transition: all 0.30s ease-in-out;
		-o-transition: all 0.30s ease-in-out;
		outline: none;
		width: 100%;
		padding: 5px;
		margin-bottom: 10px;
		border: 1px solid $light-grey;

	}

	input:focus {
		  box-shadow: 0 0 5px $color-red;
		  border: 1px solid $color-red;
		}

	button {
		border: 0px;
		background-color: $color-red;
		color: white;
		padding: 5px 10px;
		border-radius: 5px;
		margin-top: 20px;
		outline: none;

	}

	button:focus {
		  box-shadow: 0 0 5px $color-red;
		  border: 1px solid $color-red;
		}

	.closeshare {
			color: $color-red;
			position: absolute;
			top: 20px;
			right: 20px;
			font-size: 1.5em;
		}
}

#main {
	position: relative;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	z-index: 1010;
	opacity: 1;

	.fullimage {
		position: fixed;
		top: 100px;
		left: 0px;
		width: 100%;
		opacity: 0;
	}

	.img-wrapper{
		position: relative;
		width: 100vw;
		height: 100vh;
	

			#main-img {
				position: absolute;
				width: 100%;
				height: auto;
				cursor: grab;
			}

			#hover-img {
				position: absolute;
				width: 100%;
				height: auto;
				opacity:0;
				transition:0.8s;
			}

			.showhover {
				opacity: 1 !important;
			}

		
			
			#marker-wrapper {
				
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: auto;
				cursor: grab;

					.markercontent {
						position: relative;
					}

					.marker {
							transition: all .2s ease-in-out;
							position: absolute;
							border-radius: 50%;
						    width: 40px;
						    height: 40px;
						    padding: 8px;
						
							text-align: center;
        					vertical-align: middle;
							box-shadow: 0px 0px 5px #333;
							color:#fff;
							background: $color-red;
							cursor: pointer;

							font-weight: bold;
							font-size: 20px;
							line-height: 24px;
							opacity: 1;

					}

					.markera {
						opacity:1;
						transition:0.8s;
					}

					.showhover {
						opacity: 0 !important;
					}

					.marker:hover,
					.marker_b1:hover {
						transform: scale(1.1); /* Equal to scaleX(0.7) scaleY(0.7) */
						box-shadow: 0px 0px 10px #333;
					}

					.marker_b:hover {
						margin:0px 0px 0px 5px;
					}
			
				
					.marker_b {
						transition: all .2s ease-in-out;

						position: absolute;
						left:30px;
						border-radius: 8px;
					    padding: 2px 20px;
					    margin:0px;
					
						text-align: center;
    					vertical-align: middle;

						color: white;
						box-shadow: 0px 0px 5px #333;
						background: $color-red;
						cursor: pointer;

						font-weight: bold;
						text-transform: uppercase;
						font-size: 11px;
					}

					.linebefore {
						position: absolute;
						left:0px;
					}

					.linebefore::before {
						content: " ";
						width:40px;
						height: 15px;
						position:absolute;
						left:0px;
						border-bottom: 1px solid $color-red;
					}

					.linebefore::after {
						content: " ";
						width:8px;
						height:8px;
						background-color: $color-red;
						position:absolute;
						top: 11px;
						left:0px;
						border-radius:50%;
					}


					.marker_b1 {
						transition: all .2s ease-in-out;
						position: absolute;
						border-radius: 50%;
  					    width: 40px;
					    height: 40px;
					    padding: 8px;
					
						text-align: center;
    					vertical-align: middle;

						color: white;
						box-shadow: 0px 0px 5px #333;
						background: $color-red;
						cursor: pointer;

						font-weight: bold;
						font-size: 20px;
						line-height: 24px;
						display: none;
					}

					#marker1{
						
						top: $marker1-top;
						left: $marker1-left;
					}

					#marker2{
						
						top: $marker2-top;
						left: $marker2-left;
					}

					#marker3{
					
						cursor: pointer;

						top: $marker3-top;
						left: $marker3-left;
					}

					#marker4{
						
						top: $marker4-top;
						left: $marker4-left;
					}

					#marker5{
						
						top: $marker5-top;
						left: $marker5-left;
					}

					#marker6{
						
						top: $marker6-top;
						left: $marker6-left;
					}


					#marker7{
						
						top: $marker7-top;
						left: $marker7-left;
					}

					#marker8{
						
						top: $marker8-top;
						left: $marker8-left;
					}

					#marker9{
						
						top: $marker9-top;
						left: $marker9-left;
					}


					#marker10{
						
						top: $marker10-top;
						left: $marker10-left;
					}

					#marker11{
						
						top: $marker11-top;
						left: $marker11-left;
					}

					#marker12{
						
						top: $marker12-top;
						left: $marker12-left;
					}

					#marker13{
						
						top: $marker13-top;
						left: $marker13-left;
					}

					#marker14{
						
						top: $marker14-top;
						left: $marker14-left;
					}


					// Markers B1

					#markerb1{
						
						top: $markerb1-top;
						left: $markerb1-left;
					}

					#markerb1_1
					{
						
						top: $markerb1_1-top;
						left: $markerb1_1-left;
					}

					#markerb1_2
					{
						
						top: $markerb1_2-top;
						left: $markerb1_2-left;
					}

					#markerb1_3
					{
						
						top: $markerb1_3-top;
						left: $markerb1_3-left;
					}

					#markerb1_4
					{
						
						top: $markerb1_4-top;
						left: $markerb1_4-left;
					}

					#markerb1_5
					{
						
						top: $markerb1_5-top;
						left: $markerb1_5-left;
					}


					#markerb1_6
					{
						
						top: $markerb1_6-top;
						left: $markerb1_6-left;
					}

					#markerb1_7
					{
						
						top: $markerb1_7-top;
						left: $markerb1_7-left;
					}


					
			}

	}
}

#content {
	width: 50vw;
	height: 100vh;
	overflow-y:auto;
	background-color: white;
	position: absolute;
	right: 0px;
	top: 0px;
	padding: 100px 5%  0px  5%;
	z-index: 1010;

	#contentcontainer
	{
		padding-bottom: 100px;
		width: 100%;
	}

	.closecontent {
		color: $color-red;
		position: absolute;
		top: 0px;
		right: 0px;
		font-size: 1.5em;
	}

	.gototop {
		color: $color-red;
		position: fixed;
		bottom: 20px;
		right: 20px;
		font-size: 1.5em;
		opacity: 0;
	}



	.arrow {
		color: $color-red;
		font-size: 1.5em;
		margin-right: 15px;
	}

	.pages {
		position: absolute;
		right: 40px;
		top: 0px;
	}

	.inwinnnen {
		position: absolute;
		left: 15px;
		top: -60px;
		font-size: 14px;
		color: $dark-grey;

		.arrow {
			font-size: 1em;
			margin-right: 0px;
			color: $dark-grey;
		}
	}

	.hoofdplaat {
		position: absolute;
		left:50%;
		margin-left: -75px;
		top: -60px;
		font-size: 14px;
		color: $dark-grey;
		width: 150px;
		text-align: center;
	}


	.gebruik {
		position: absolute;
		right: 0px;
		top: -60px;
		font-size: 14px;
		color: $dark-grey;

		.arrow {
			font-size: 1em;
			margin-right: 0px;
			color: $dark-grey;
		}

	}
}

#info_menu {
	position: relative;
	z-index: 0;
	opacity: 0;
	top: 45px;
	
	div {
		width: 100%;
		margin-left: 0px;
	}



	ul {
		margin: 0px;
		padding: 0px;
		margin-top: 10px;
		position: relative;
		z-index:100;

		li {
			list-style: none;;
			display: inline-block;
			padding: 5px 0px 5px 0px;
			background-color: #e6e6e6;
			border-bottom:1px solid #f2f2f2;
			margin-left: 0px;
			box-shadow: 0px 0px 2px #d1d1d1;
			width:120px;

		}
		
		li:hover,
		li.active {
				color: $color-red;
		}

		li:first-child {
			border-radius: 5px 0px 0px 5px;
			border-left:1px solid #f2f2f2;
		}

		li:last-child {
			border-radius: 0px 5px 5px 0px;
			border-right:1px solid #f2f2f2;
		}
	}


	ul:last-child {
		margin: 0px;
		padding: 0px;
		margin-top: -2px;
		position: relative;
		z-index:10;

		li {
			list-style: none;;
			display: inline-block;
			padding: 1px 0px 1px 0px;
			background-color: #f2f2f2;
			margin-left: 0px;
			border-radius: 5px;
			box-shadow: 0px 0px 0px #d1d1d1;
			font-size:0.8em;
			width: 120px;
		}


		li:hover,
		li.active {
				color: $color-red;
		}

	}

}

#info {
	width: 100vw;
	height: 100vh;
	overflow-y:auto;
	background-color: white;
	position: absolute;
	right: 0px;
	top: 0px;
	padding: 150px  5%  0px  5%;
	z-index: 1020;
	opacity: 0;

	
	#infocontainer {
		width: 100%;
		position: relative;

		.closeinfo {
			color: $color-red;
			position: absolute;
			top: 0px;
			right: 0px;
			font-size: 1.5em;
		}

		.gototop {
			color: $color-red;
			position: fixed;
			bottom: 20px;
			right: 20px;
			font-size: 1.5em;
			opacity: 0;
		}

	}
}


#zoom {
	color: $color-red;
	position: absolute;
	bottom: 20px;
	right: 20px;
	background-color: white;
	border-radius: 20px 20px 20px 20px;
	box-shadow: 0px 0px 5px #d1d1d1;
	z-index: 1010;

	.reset {
		color: $color-red-light;
		width: 40px;
		height: 40px;
		padding: 10px;
		text-align: center;
		border-bottom:1px solid $light-grey;
		cursor: pointer;
		i {
			font-size: 1.2em;
		}
	}

	.plus {
		width: 40px;
		height: 40px;
		padding: 10px;
		text-align: center;
		border-bottom:1px solid $light-grey;
		cursor: zoom-in;
		i {
			font-size: 1.2em;
		}
	}
	
	.minus {
		width: 40px;
		height: 40px;
		padding: 10px;
		text-align: center;
		i {
			font-size: 1.2em;
			position: relative;
			top: -3px;
		}
	}
}


// ANIMATIONS


@keyframes flickerAnimation {
  0%   { background-color:$color-red; }
  50%  { background-color:black; }
  100% { background-color:$color-red; }
}
@-o-keyframes flickerAnimation{
  0%   { background-color:$color-red; }
  50%  { background-color:black; }
  100% { background-color:$color-red; }
}
@-moz-keyframes flickerAnimation{
  0%   { background-color:$color-red; }
  50%  { background-color:black; }
  100% { background-color:$color-red; }
}
@-webkit-keyframes flickerAnimation{
  0%   { background-color:$color-red; }
  50%  { background-color:black; }
  100% { background-color:$color-red; }
}
.animate-button {
   -webkit-animation: flickerAnimation 3s infinite;
   -moz-animation: flickerAnimation 3s infinite;
   -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
}


// Media queries

@media (max-width: 1450px) { 
	#homepage::after {
		content: '';
		background-image: $intro-image;
		background-repeat: no-repeat;
		background-position: center right;
		background-size: cover;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1; 
		opacity: 0.5;  
	}
}



@media (max-width: 1258px) { 
	#homepage::after {
		content: '';
		background-image: $intro-image;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1; 
		opacity: 0.3;  
	}
}



@media (max-width: 1000px) { 
	#homepage::after {
		content: '';
		background-image: $intro-image;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1; 
		opacity: 0.4;  
	}
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 991.98px) { 
	.gototop {
		display: none !important;
	}

	.breadcrumbs {
		visibility: hidden;
	}


	#langbutton {
		position: fixed;
		right: 20px;
		bottom: 80px;
		width: 100px;
		height: 30px;
		text-align: center;
		z-index:1100;
		}

	#content {
			.hoofdplaat {
			position: absolute;
			left:50%;
			margin-left: -60px;
			top: -60px;
			font-size: 12px;
			color: $dark-grey;
			width: 150px;
			text-align: center;
		}

		#contentcontainer
		{
			padding-bottom: 60vh;
			width: 100%;
		}

		.inwinnnen {
		font-size: 12px;
		}

		
		.gebruik {
			font-size: 12px;
		}

		.configuratiedata {
			top: 0px;
		}
	}


	h1, h2 {
		font-size: 1.2em;
	}

	#homepage {
		background-image: none;
		padding: 10px 0px 0px 0px;



	}
	
	#sidebar {
		ul{
			li.bottom {
				position: absolute;
				bottom: 50px;
			}
		}
	}
}




